:root {
  --lightestGray: rgb(230, 220, 220);
  --lightGray: rgb(207, 199, 199);
  --darkGray: rgb(145, 145, 145);
  --darkestGray: rgb(82, 79, 79);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*custom css*/
.content {
  margin: 5px 20px;
  text-align: left;
}

/*navbars*/
.main-nav {
  height: 50px;
  background-color: var(--lightGray);
}

.main-nav ul {
  float: left;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.main-nav ul li.nav-li {
  display: inline-block;
  padding-top: 12px;
}

.main-nav div.nav-li-search{
  float: right;
  padding-right: 20px;
  padding-top: 10px;
}

.main-nav ul.nav-ul li.nav-li a {
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.main-nav div.nav-li-search a {
  color: rgb(0, 0, 0);
  text-align: left;
  padding: 2px 15px;
  text-decoration: none;
}

.main-nav ul.nav-ul li.nav-li a:hover {
  color: rgb(63, 3, 3);
  text-align: center;
  padding: 14px 16px;
}

.admin-menu {
  margin-bottom: 30px;
  height: 50px;
  background-color: var(--darkGray);
}

.admin-menu ul {
  float: left;
  list-style-type: none;
  margin: 0;
  padding: 0;
} 

.admin-menu ul.nav-ul li.nav-li{
  display: inline-block;
  padding-top: 12px;
}

.admin-menu ul.nav-ul li.nav-li a {
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px 10px;
  text-decoration: none;
}

.admin-menu ul.nav-ul li.nav-li a:hover {
  color: rgb(41, 25, 25);
  text-align: center;
  padding: 10px 10px;
}

/*tables*/
.table-header {
  text-align: left;
}

table {
  border: var(--darkestGray) solid 1px;
}

table th {
  border: var(--darkestGray) solid 1px;
  background-color: var(--darkGray);
  text-align: center;
  min-width: 40px;
}

table th.click-header:hover {
  background-color: var(--darkestGray);
  cursor: pointer;
}

table td {
  border: var(--darkGray) solid 1px;
  background-color: var(--lightestGray);
  text-align: center;
}

tbody tr:hover td {
  background-color: rgb(236, 235, 144);
  border: rgb(148, 143, 79) solid 1px;
}

/*player bio*/
.player-attributes {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/*dropdowns*/
.custom-dropdown {
  display: inline-block;
  border: black solid 1px;
  border-radius: 5px;
  background-color: var(--darkGray);
  color: black;
}

.custom-dropdown #dropdown-basic {
  color: black;
}

/*season form*/
.season-form {
  margin-bottom: 20px;
}

/*result form*/
.result-form {
  margin-bottom: 20px;
}

/*player form*/
.player-form label {
  display: block;
}

.player-form .player-input {
  margin-left: 20px;
}

/*results dropdown*/
.results-dropdown ul {
  list-style-type: none;
  display: block;
  border: black solid 1px;
  background-color: white;
  padding: 0px;
}

.results-dropdown ul li {
  margin: 0px;
  padding: 0px;
  text-align: left;
}

.results-dropdown {
  /* width: 78%; */
  position: absolute;
  left: 98px;
}

.nav-li-search {
  position: relative;
  z-index: 1200;
}

